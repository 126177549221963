import React, { useRef, useState } from "react";
import { useTranslation, Link, useI18next, Trans } from "gatsby-plugin-react-i18next";

import stylesheet from "./Footer.module.css";
import FretlinkLogo from "./svg-assets/FretlinkLogo";
import homepage from "../lib/homepage";
import { SuccessCircle24 } from "./svg-assets/Icons";

const Footer = () => {
  const { t } = useTranslation();
  const { originalPath, language: currentLanguage, defaultLanguage } = useI18next();

  const handleLanguageChange = event => {
    event.preventDefault();

    const targetLanguage = event.target.value;

    if (currentLanguage !== targetLanguage) {
      if (targetLanguage === defaultLanguage) {
        window.location = originalPath;
      } else {
        window.location = `/${targetLanguage}${originalPath}`;
      }
    }
  };

  const [email, setEmail] = useState("");
  const [savingSubscription, setSavingSubscription] = useState(false);
  const [successfullySubscribed, setSuccessfullySubscribed] = useState(false);
  const [newsletterError, setNewsletterError] = useState(null);
  const newsletterInput = useRef();

  const onSubmitNewsletter = event => {
    event.preventDefault();

    setNewsletterError(null);
    setSavingSubscription(true);

    homepage.sendSubscribe(
      {
        email,
      },
      () => {
        setSavingSubscription(false);
        setSuccessfullySubscribed(true);
      },
      error => {
        setSavingSubscription(false);
        setNewsletterError(t("footer.newsletter.generic-error-message"));
        if (newsletterInput.current) {
          newsletterInput.current.focus();
        }
      }
    );
  };

  return (
    <footer className={`fl-grid ${stylesheet.footer}`}>
      <div className={stylesheet.brandLogo}>
        <Link to="/">
          <FretlinkLogo />
        </Link>
      </div>
      <div className={stylesheet.newsletterLabel}>
        <Trans i18nKey="footer.newsletter.label" />
      </div>
      {!successfullySubscribed ? (
        <form
          className={`${stylesheet.newsletterForm} ${
            savingSubscription ? stylesheet.newsletterFormSaving : ""
          }`}
          onSubmit={onSubmitNewsletter}>
          <input
            name="email"
            placeholder={t("footer.newsletter.placeholder")}
            onChange={event => setEmail(event.currentTarget.value)}
            ref={newsletterInput}
          />
          <button type="submit">{t("footer.newsletter.subscribe")}</button>
          <div className={stylesheet.newsletterLegalese}>
            {newsletterError ? (
              <p>
                <strong>{newsletterError}</strong>
              </p>
            ) : null}
            <p>
              {/* eslint-disable jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content */}
              <Trans
                i18nKey="footer.newsletter.legalese"
                components={{
                  privacyLink: (
                    <a
                      href={t("footer.newsletter.privacy-policy-url")}
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                }}
              />
              {/* eslint-enable jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content */}
            </p>
            <p>
              <Trans i18nKey="footer.newsletter.reassurance" />
            </p>
          </div>
        </form>
      ) : (
        <div className={stylesheet.newsletterForm}>
          <p className={stylesheet.newsletterSuccess}>
            <SuccessCircle24 />
            <Trans i18nKey="footer.newsletter.success" />
          </p>
        </div>
      )}
      <div className={stylesheet.languageColumn}>
        <div className={stylesheet.title}>{t("footer.language")}</div>
        {/* eslint-disable-next-line jsx-a11y/no-onchange */}
        <select
          className={stylesheet.languageSelector}
          value={currentLanguage}
          onChange={handleLanguageChange}>
          <option value="fr">{t("footer.languages.french")}</option>
          <option value="en">{t("footer.languages.english")}</option>
        </select>
      </div>
      <div className={stylesheet.firstColumn}>
        <div className={stylesheet.title}>{t("footer.services-header")}</div>
        <Link to="/shippers/">{t("footer.links.shippers")}</Link>
        <Link to="/carriers/">{t("footer.links.carriers")}</Link>
        <Link to="/green-roads/">{t("footer.links.green-roads")}</Link>
      </div>
      <div className={stylesheet.secondColumn}>
        <div className={stylesheet.title}>{t("footer.fretlink-header")}</div>
        <Link to="/about-us/">{t("footer.links.about-us")}</Link>
        <a href="https://blog.fretlink.com/">{t("footer.links.blog")}</a>
        <a href="https://www.welcometothejungle.com/fr/companies/fretlink">
          {t("footer.links.join-us")}
        </a>
      </div>
      <div className={stylesheet.thirdColumn}>
        <div className={stylesheet.title}>{t("footer.ressources-header")}</div>
        <Link to="/sign-up">{t("footer.links.sign-up")}</Link>
        <Link to="/contact">{t("footer.links.contact")}</Link>
      </div>
      <p className={stylesheet.copyright}>
        {t("footer.copyright", { year: new Date().getFullYear() })}
      </p>
      <nav className={stylesheet.termsNav}>
        {/* Those links should not be <Link>s as they are not redirecting to Gatsby pages. */}
        <a href={`/${currentLanguage}/utilisation-des-cookies/`}>
          {t("footer.links.cookies-usage")}
        </a>
        <Link to="/terms/">{t("footer.links.terms-of-service")}</Link>
        <a href={`/${currentLanguage}/mentions-legales/`}>{t("footer.links.legal")}</a>
        <a href={`/${currentLanguage}/confidentialite/`}>{t("footer.links.privacy")}</a>
      </nav>
    </footer>
  );
};

export default Footer;
