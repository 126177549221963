import React, { useEffect, useRef, useState } from "react";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import stylesheet from "./Header.module.css";
import HeaderLogo from "./svg-assets/HeaderLogo";
import { Menu24, SignIn24, Close24 } from "./svg-assets/Icons";

function useOutsideClick(ref, handler) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handler, ref]);
}

const Header = () => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const headerRef = useRef(null);

  useOutsideClick(headerRef, () => {
    if (opened) {
      setOpened(false);
    }
  });

  const toggleMenu = event => {
    setOpened(!opened);
    event.currentTarget.blur();
  };

  return (
    <header
      className={`fl-grid ${stylesheet.header} ${opened ? stylesheet.opened : ""}`}
      ref={headerRef}>
      <h1 className={stylesheet.headerBrand}>
        <Link to="/">
          <HeaderLogo />
        </Link>
      </h1>
      <button className={stylesheet.menuButton} onClick={toggleMenu}>
        {opened ? <Close24 /> : <Menu24 />}
      </button>
      <nav className={stylesheet.headerLinks}>
        <Link to="/shippers/">{t("header.links.shippers")}</Link>
        <Link to="/carriers/">{t("header.links.carriers")}</Link>
        <Link className={stylesheet.greenRoadsLink} to="/green-roads/">
          {t("header.links.green-roads")}
        </Link>
        <Link to="/about-us/">{t("header.links.about-us")}</Link>
        <a href="https://blog.fretlink.com/">{t("header.links.blog")}</a>
        <Link to="/login/" className={stylesheet.login}>
          <SignIn24 />
          <span>{t("header.links.sign-in")}</span>
        </Link>
      </nav>
    </header>
  );
};

export default Header;
