import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { useCallback, useState } from "react";
import { Trans } from "react-i18next";

import stylesheet from "./CookieWarning.module.css";

// This cookie is used by gatsby-plugin-google-gtag to disable any Google tracking.
const cookieDisablerName = `ga-disable-${process.env.HOMEPAGE_GA_ACCOUNT}`;
const inOneYear = new Date(Date.now() + 1000 * 3600 * 24 * 365).toUTCString();

export const CookieWarning = () => {
  const { i18n } = useTranslation();
  const [isClosed, setIsClosed] = useState(false);
  const secure = window.location.protocol === "https:" ? "; Secure" : "";

  const acceptCookies = useCallback(() => {
    document.cookie = `accepted_cookies=1; expires=${inOneYear}; path=/; SameSite=Strict${secure}`;

    setIsClosed(true);
  }, [secure]);

  const delineCookies = () => {
    document.cookie = `${cookieDisablerName}=true; expires=${inOneYear};path=/; SameSite=Strict${secure}`;

    window[cookieDisablerName] = true;

    if (window.gtag) {
      window.gtag.decline();
    }

    setIsClosed(true);
  };

  const allCookies = document.cookie.split("; ");
  const acceptedCookies = allCookies.includes("accepted_cookies=1");
  const declinedCookies = allCookies.includes(`${cookieDisablerName}=true`);

  if (acceptedCookies || declinedCookies || isClosed) {
    return null;
  } else {
    return (
      <div className={stylesheet.cookies}>
        <Trans parent="p" i18nKey="common.cookies.p-explanations" />
        <div className={stylesheet.cookiesChoices}>
          <a href={`/${i18n.language}/utilisation-des-cookies/`}>
            <Trans i18nKey="common.cookies.learn-more" />
          </a>
          <button
            onClick={event => {
              event.preventDefault();
              delineCookies();
            }}>
            <Trans i18nKey="common.cookies.decline" />
          </button>
          <button
            className={stylesheet.acceptCookies}
            onClick={event => {
              event.preventDefault();
              acceptCookies();
            }}>
            <Trans i18nKey="common.cookies.accept" />
          </button>
        </div>
      </div>
    );
  }
};
