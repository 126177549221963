export class HomepageAPI {
  constructor() {
    this.baseUrl = process.env.HOMEPAGE_API_BASE_URL.replace(/\/$/,"");

    this.prepareLeadData = this.prepareLeadData.bind(this);
  }

  get(path, data) {
    const query = data ? `?${new URLSearchParams(data)}` : "";
    return fetch(`${this.baseUrl}${path}${query}`, {
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    }).then(response => response.json());
  }

  post(path, data) {
    return fetch(`${this.baseUrl}${path}`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        return response.json().then(data => {
          return Promise.reject({ response: data });
        });
      }
    });
  }

  patch(path, data) {
    return fetch(`${this.baseUrl}${path}`, {
      method: "PATCH",
      credentials: "include",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      if (response.ok) {
        if (response.length > 0) {
          return response.json();
        } else {
          return Promise.resolve({});
        }
      } else {
        return response.json().then(data => {
          return Promise.reject({ response: data });
        });
      }
    });
  }

  /*
   * Map Frontend inputs fields to API fields
   */
  prepareLeadData(formData, fieldNames) {
    let leadData = {};
    let geoRangeData = { fromContinent: "Europe", toContinent: "Europe" };
    let { leadId } = formData;

    fieldNames.forEach(inputField => {
      if (this.isGeoRangeField(inputField)) {
        geoRangeData = Object.assign(geoRangeData, this.geoRangeMapping(formData, inputField));
      } else {
        leadData[inputField] = formData[inputField];
      }
    });

    // Add Geo Range info
    if (Object.keys(geoRangeData)?.length > 2) {
      leadData.geoRange = geoRangeData;
    }

    // Always keep leadId if it's present (it's not an input field)
    if (leadId) {
      leadData.id = leadId;
    }

    return leadData;
  }

  /*
   * POST or PATCH lead data to /lead/<carrier|shipper>
   */
  sendLead(leadType, data, onSuccess, onError) {
    if (!onSuccess) {
      onSuccess = this.defaultSuccessHandler;
    }
    if (!onError) {
      onError = this.defaultErrorHandler;
    }

    let uri = `/lead/${leadType}`;
    let req = data.id ? this.patch(`${uri}/${data.id}`, data) : this.post(uri, data);

    req.then(onSuccess).catch(onError);
  }

  validateLead(leadType, leadId, onSuccess = null, onError = null) {
    if (!onSuccess) {
      onSuccess = this.defaultSuccessHandler;
    }
    if (!onError) {
      onError = this.defaultErrorHandler;
    }

    let uri = `/lead/${leadType}/${leadId}/validate`;
    let req = this.post(uri);

    req.then(onSuccess).catch(onError);
  }

  /*
   * POST a message to /requests/contact
   */
  sendContact(data, onSuccess, onError) {
    if (!onSuccess) {
      onSuccess = this.defaultSuccessHandler;
    }
    if (!onError) {
      onError = this.defaultErrorHandler;
    }

    this.post("/contact/contact", data).then(onSuccess).catch(onError);
  }

  /*
   * POST contact information to subscribe to the newsletter /newsletter/subscribe
   */
  sendSubscribe(data, onSuccess, onError) {
    if (!onSuccess) {
      onSuccess = this.defaultSuccessHandler;
    }
    if (!onError) {
      onError = this.defaultErrorHandler;
    }

    this.post("/newsletter/subscribe", data).then(onSuccess).catch(onError);
  }

  /*
   * GET blog posts /blog/posts
   */
  getBlogPosts(limit = 6, lang = "en") {
    return this.get("/blog/posts", { limit, lang });
  }

  postCovid19CarrierNeeds(data, onSuccess, onError) {
    this.post("/covid/carriers", data).then(onSuccess).catch(onError);
  }

  // Private

  defaultSuccessHandler(success) {
    console.log("== DEBUG: API call success! ==");
    console.log(success);

    return success;
  }

  defaultErrorHandler(error) {
    console.log("== DEBUG: API call error ==");
    console.log(error);

    return error;
  }

  isGeoRangeField(field) {
    return ["to", "from"].includes(field);
  }

  geoRangeMapping(formData, fieldName) {
    const geo = formData[fieldName];

    return {
      [`${fieldName}Country`]: geo.country === "Europe" ? "" : geo.country,
      [`${fieldName}Region`]: geo.all ? "" : geo.region,
      [`${fieldName}Continent`]: "Europe",
    };
  }
}

export default new HomepageAPI();
