import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./Header";
import "./base.css";
import "./Layout.css";
import "../fonts/fonts.css";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { CookieWarning } from "./CookieWarning";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "fl-dark-blue-background",
        }}
      />
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      {children}
      <Footer />
      {typeof window !== "undefined" ? <CookieWarning /> : null}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
