import React from "react";

export default function HeaderLogo() {
  return (
    <svg width="171" height="32" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M75.53 8.29c5.73 0 10.39 4.65 10.39 10.37v.13c0 .4-.01 1.69-.06 2.96l-.02.4-15.13.02a4.85 4.85 0 008.7 2.38l.1-.16h6.03A10.31 10.31 0 0175.53 32a10.4 10.4 0 01-10.38-10.36v-2.97c0-5.72 4.65-10.37 10.38-10.37zM109 .06v31.37h-6.1V4.99l4.94-4.93H109zm9.95 8.81v22.56h-6.1V8.87h6.1zM154.4.06v16.72l7.93-7.91h7.6l-10.87 10.88 11.7 11.68h-7.98l-8.38-8.36v8.36h-6.1V4.99l4.94-4.93h1.16zm-20.7 8.22c2.86 0 5.54 1.1 7.55 3.12a10.58 10.58 0 013.13 7.53v12.5h-6.12v-12.5a4.51 4.51 0 00-4.55-4.54 4.55 4.55 0 00-4.55 4.54v12.5h-6.12v-12.5c0-5.87 4.78-10.65 10.67-10.65zm-70.2 0v6.1a4.55 4.55 0 00-4.56 4.55v12.5h-6.11v-12.5c0-5.87 4.78-10.65 10.67-10.65zm31.28-5.3v5.89h4.56v4.9h-4.56v8.5a3.06 3.06 0 003.06 3.05h1.5v6.1h-1.5a9.18 9.18 0 01-9.17-9.16V7.92l4.94-4.94h1.17zM52.02 0v5.2h-2.28c-2.3 0-3.36 1.84-3.42 3.67h5.7v4.9h-5.7v17.66H40.3V13.77h-3.22v-4.9h3.22C40.38 3.75 44.58 0 49.74 0h2.28zm23.51 13.82a4.85 4.85 0 00-4.7 3.7h9.4a4.85 4.85 0 00-4.7-3.7zM115.9.06a3.24 3.24 0 110 6.48 3.24 3.24 0 010-6.48z"
          fill="currentColor"
          id="fl-fretlink-logo-brand-name"
        />
        <path
          d="M26.26 4.25a5.92 5.92 0 00-9.57 4.62A10.77 10.77 0 015.9 19.64a5.9 5.9 0 105.9 5.9 10.76 10.76 0 0110.8-10.78 5.89 5.89 0 003.66-10.51"
          fill="#288CFF"
        />
      </g>
    </svg>
  );
}
